import React from 'react'
import Layout from "../components/layout"

import "../styles/style.scss"

const NotFoundPage = () => {

  return (

    <Layout pathname={"404"}>
        <section>
          <div className="container notFoundPage">
            <div className="row row___max notFoundPage-header">
                <div className="col col12">
                  <h1>404 - PAGE NOT FOUND</h1>
                </div>
            </div>
            <div className="row row___max">
              <div className="col col12">
                <h3>Oops, something went wrong</h3>
              </div>
            </div>
          </div>
        </section>
    </Layout>
  )
}

export default NotFoundPage
